import { useState, useEffect } from "react";

function UserComponent(props){
    const startingLifeNum = parseInt(props.startingLife, 10)
    const clickIncrementNum = parseInt(props.clickIncrement, 10)

    const [life, setLife] = useState(startingLifeNum);
    const [recentLifeChange, setRecentLifeChange] = useState(0);
    const clickIncrement = clickIncrementNum;
    const less = "<";
    const more = ">";

    const componentStyle = {
        borderColor: props.color,
        boxShadow: `0 0 6px ${props.color}`,
    };

    const lifeStyle = {
        color: life <= 0 ? '#ae0c00' : '#f1faee',
    };
    const changeLifeStyle = {
        color: recentLifeChange <= 0 ? '#9b2226' : '#bcb8b1',
    };
    

    useEffect(() => {
        let timeoutId;

        function resetRecentLifeChange() {
        console.log('Life Changed By: ' + recentLifeChange);
        setRecentLifeChange(0);
        }

        timeoutId = setTimeout(resetRecentLifeChange, 3000);

        return () => clearTimeout(timeoutId);
    }, [recentLifeChange]);

    function subtractLife() {
      setLife((prevLife) => prevLife - clickIncrement);
      setRecentLifeChange((prevLifeChange) => prevLifeChange - clickIncrement);
    }
  
    function addLife() {
      setLife((prevLife) => prevLife + clickIncrement);
      setRecentLifeChange((prevLifeChange) => prevLifeChange + clickIncrement);
    }
  


    return(
        <div className="usercomponent" style={componentStyle}>
            <button className="subtract-health-button" onClick={subtractLife}>{less}</button>
            {recentLifeChange !== 0 && ( <p className="usercomponent--life-change" style={changeLifeStyle}>{}{recentLifeChange > 0 ? `+${recentLifeChange}` : recentLifeChange}</p>
)}
            <p className="usercomponent--life" style={lifeStyle}>{life}</p>
            <button className="add-health-button" onClick={addLife}>{more}</button>
        </div>
    );
}

export default UserComponent;